import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import RequireAuth from './RequireAuth'
import MainLayout from 'Component/MainLayout/MainLayout'
import LoginPage from 'Pages/LoginPage'
import NotFound from 'Pages/NotFound'
import MainLoader from 'Component/Common/MainLoader'
import UserManagment from 'Pages/UserManagment'
import Customer from 'Pages/Customer'
import AllLead from 'Pages/AllLead'
import Sales from 'Pages/Sales'
import GSTROne from 'Pages/GSTROne'
import Purchase from 'Pages/Purchase'
import GSTRTwo from 'Pages/GSTRTwo'
import Challan from 'Pages/Challan'
import GSTJV from 'Pages/GSTJV'
import GSTRThree from 'Pages/GSTRThree'
import Bank from 'Pages/Bank'
import SBBank from 'Pages/SBBank'
import SUS from 'Pages/SUS'
import LoanStmt from 'Pages/LoanStmt'
import Debit from 'Pages/Debit'
import Credit from 'Pages/Credit'
import { useAppSelector } from 'Hooks/ReduxHook'
import ProfilePage from 'Pages/ProfilePage'

const Dashboard = lazy(async () => await import('Pages/Dashboard'))

// no lazy loading for auth pages to avoid flickering
const Logout = React.lazy(async () => await import('./Logout'))

export const AppRouter: React.FC = () => {
  const isValid = useAppSelector((state) => state.auth.isValid)

  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  )

  const DASHBOARD_PATH = '/'

  return (
    <BrowserRouter>
      <Suspense fallback={<MainLoader />}>
        <Routes>
          <Route path={DASHBOARD_PATH} element={protectedLayout}>
            <Route path={DASHBOARD_PATH} element={isValid ? <Dashboard /> : <AllLead />} />
            <Route path="/user" element={<UserManagment />} />
            <Route path="/customer" element={<Customer />} />

            {/* <Route path="/lead" element={<AllLead />} /> */}
            <Route path="/sales" element={<Sales />} />
            <Route path="/gstr-1" element={<GSTROne />} />
            <Route path="/purchase" element={<Purchase />} />
            <Route path="/gstr-2b" element={<GSTRTwo />} />
            <Route path="/challan" element={<Challan />} />
            <Route path="/gst-jv" element={<GSTJV />} />
            <Route path="/gstr-3b" element={<GSTRThree />} />
            <Route path="/bank" element={<Bank />} />
            <Route path="/sbbank" element={<SBBank />} />
            <Route path="/sus" element={<SUS />} />
            <Route path="/loanstmt" element={<LoanStmt />} />
            <Route path="/debit" element={<Debit />} />
            <Route path="/credit" element={<Credit />} />

            <Route path="/profile" element={<ProfilePage />} />

            {/* <Route path="/user-report" element={<UserReport />} />
            <Route path="/sales-report" element={<SalesReport />} />
            <Route path="/sales-pending" element={<SalesPendingReport />} /> */}

            {/* <Route path="/user-report" element={<UserReport />} />
            <Route path="/sales-pending" element={<SalesPendingReport />} />
            <Route path="/sales-report" element={<SalesReport />} />
            <Route path="/purchase-pending" element={<PurchasePendingReport />} />
            <Route path="/purchase-done" element={<PurchaseDoneReport />} />
            <Route path="/gstrone-pending" element={<GSTROnePendingReport />} />
            <Route path="/gstrthree-pending" element={<GSTRThreePendingReport />} /> */}
          </Route>

          <Route path="login" element={<LoginPage />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
