import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Space } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { type ILead } from 'Util/Interface'
import { toast } from 'sonner'
import dayjs from 'dayjs'
import CommonModal from 'Component/Common/CommonModal'

const Debit: React.FC = () => {
  const [tableData, setTableData] = useState<ILead[]>([])
  const [visible, setVisble] = useState(false)
  const [recordId, setRecordId] = useState('')

  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/lead?step=gstr3`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
  }, [fetch])

  const columns: ColumnsType<ILead> = [
    {
      title: 'Sr. No.',
      key: 'serial',
      dataIndex: 'serial',
      render: (_value, _record, index: number) => index + 1,
    },
    {
      title: 'Month',
      key: 'month',
      dataIndex: 'month',
    },
    {
      title: 'Customer',
      key: 'cust',
      dataIndex: 'cust',
      render: (_value, record: ILead) => <span>{record?.customer_data?.fullName}</span>,
    },
    {
      title: 'Added By',
      key: 'usr',
      dataIndex: 'usr',
      render: (_value, record: ILead) => <span>{record?.debit_user?.fullName || '-'}</span>,
    },
    {
      title: 'Debit Status',
      key: 'debitStatus',
      dataIndex: 'debitStatus',
      render: (value: string) => <span className="text-uppercase">{value || 'Pending'}</span>,
    },
    {
      title: 'Debit Filled Date',
      key: 'debitDate',
      dataIndex: 'debitDate',
      render: (value) => <span>{value ? dayjs(value).format('DD-MM-YYYY') : '-'}</span>,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      width: '15%',
      render: (_text, record: ILead) => {
        return (
          <Space>
            {!record?.debitUserId && (
              <Button
                onClick={() => {
                  setRecordId(record?.id)
                  setVisble(true)
                }}
              >
                Action
              </Button>
            )}
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Debit List</h3>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: ILead) => dataIndex?.id} scroll={{ x: 800 }} bordered size="middle" />
        </Card>
      </div>
      <CommonModal
        visible={visible}
        setVisible={() => {
          setVisble(false)
          void fetch()
        }}
        action={'debit'}
        recordId={recordId}
      />
    </>
  )
}

export default Debit
