import React from 'react'
import { DashboardOutlined, HomeOutlined, UserAddOutlined } from '@ant-design/icons'

export interface SidebarNavigationItem {
  title: string
  key: string
  url?: string
  children?: SidebarNavigationItem[]
  icon?: React.ReactNode
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Dashboard',
    key: 'dashboard',
    url: '/',
    icon: <DashboardOutlined />,
  },
  {
    title: 'Master',
    key: 'customer',
    icon: <UserAddOutlined />,
    url: '/customer',
  },
  {
    title: 'Staff',
    key: 'user',
    icon: <UserAddOutlined />,
    url: '/user',
  },
]

export const userSidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Dashboard',
    key: 'dashboard',
    url: '/',
    icon: <DashboardOutlined />,
  },
  {
    title: 'Sales',
    key: 'sales',
    icon: <HomeOutlined />,
    url: '/sales',
  },
  {
    title: 'GSTR-1',
    key: 'gstr-1',
    icon: <HomeOutlined />,
    url: '/gstr-1',
  },
  {
    title: 'Purchase',
    key: 'Purchase',
    icon: <HomeOutlined />,
    url: '/Purchase',
  },
  {
    title: 'GSTR-2B',
    key: 'gstr-2b',
    icon: <HomeOutlined />,
    url: '/gstr-2b',
  },
  {
    title: 'Challan',
    key: 'challan',
    icon: <HomeOutlined />,
    url: '/challan',
  },
  {
    title: 'GST-JV',
    key: 'gst-jv',
    icon: <HomeOutlined />,
    url: '/gst-jv',
  },
  {
    title: 'GSTR-3B',
    key: 'gstr-3b',
    icon: <HomeOutlined />,
    url: '/gstr-3b',
  },
  {
    title: 'Bank',
    key: 'bank',
    icon: <HomeOutlined />,
    url: '/bank',
  },
  {
    title: 'SB Bank',
    key: 'sbbank',
    icon: <HomeOutlined />,
    url: '/sbbank',
  },
  {
    title: 'SUS',
    key: 'sus',
    icon: <HomeOutlined />,
    url: '/sus',
  },
  {
    title: 'Loan Stmt',
    key: 'loanstmt',
    icon: <HomeOutlined />,
    url: '/loanstmt',
  },
  {
    title: 'Debit',
    key: 'debit',
    icon: <HomeOutlined />,
    url: '/debit',
  },
  {
    title: 'Credit',
    key: 'credit',
    icon: <HomeOutlined />,
    url: '/credit',
  },
]
