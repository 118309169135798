// import { type ReportHandler } from 'web-vitals'

// const reportWebVitals = (onPerfEntry?: ReportHandler) => {
//   if (onPerfEntry != null && onPerfEntry instanceof Function) {
//     // deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>
//     void import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
//       getCLS(onPerfEntry)
//       getFID(onPerfEntry)
//       getFCP(onPerfEntry)
//       getLCP(onPerfEntry)
//       getTTFB(onPerfEntry)
//     })
//   }
// }

// export default reportWebVitals

const reportWebVitals = (onPerfEntry?: any) => {
  if (onPerfEntry != null && onPerfEntry instanceof Function) {
    // deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>
    void import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }: any) => {
      getCLS(onPerfEntry)
      getFID(onPerfEntry)
      getFCP(onPerfEntry)
      getLCP(onPerfEntry)
      getTTFB(onPerfEntry)
    })
  }
}

export default reportWebVitals
