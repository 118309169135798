import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import { sidebarNavigation, userSidebarNavigation } from './SideBarNavigation'
import { CloseOutlined } from '@ant-design/icons'
import logo from '../../Assets/images/logo.png'
import { useAppSelector } from 'Hooks/ReduxHook'

export interface SidebarNavigationItem {
  title: string
  key: string
  url?: string
  children?: SidebarNavigationItem[]
  icon?: React.ReactNode
}

interface IProps {
  showMenu: (value: boolean) => void
}

function Sidebar({ showMenu }: IProps) {
  const location = useLocation()
  const isValid = useAppSelector((state) => state.auth.isValid)

  const navBar = isValid ? sidebarNavigation : userSidebarNavigation

  const sidebarNavFlat = navBar.reduce(
    (result: SidebarNavigationItem[], current) => result.concat(current.children && current.children.length > 0 ? current.children : current),
    []
  )

  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname)
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : []

  const openedSubmenu = navBar.find(({ children }) => children?.some(({ url }) => url === location.pathname))
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : []

  return (
    <>
      <div className="side-drawer">
        <div className="sidebar-logo">
          <div className="sidebar-link">
            <img src={logo} alt="logo" height={56} />
            <div className="mobile-menu">
              <CloseOutlined
                onClick={() => {
                  showMenu(false)
                }}
                className="font-size-20"
              />
            </div>
          </div>
        </div>
        <div className="sider-content">
          <Menu
            className="sidebar-menu"
            mode="inline"
            defaultSelectedKeys={defaultSelectedKeys}
            defaultOpenKeys={defaultOpenKeys}
            items={navBar.map((nav) => {
              const isSubMenu = nav.children?.length

              return {
                key: nav.key,
                title: nav.title,
                label: isSubMenu ? nav.title : <Link to={nav.url ?? ''}>{nav.title}</Link>,
                icon: nav.icon,
                children:
                  isSubMenu &&
                  nav.children &&
                  nav.children.map((childNav) => ({
                    key: childNav.key,
                    label: <Link to={childNav.url ?? ''}>{childNav.title}</Link>,
                    title: childNav.title,
                  })),
              }
            })}
          />
        </div>
      </div>
    </>
  )
}

export default Sidebar
