import { Modal, Space, Button, Form, Select } from 'antd'
import { type AxiosError } from 'axios'
import { toast } from 'sonner'
import { OPTION_LIST } from 'Util/Constant'
import Http, { type ApiErrorData } from 'Util/Http'
import { HTTP_METHOD, type IAddLead } from 'Util/Interface'

interface IProps {
  visible: boolean
  setVisible: () => void
  action: string
  recordId: string
}

const CommonModal = (props: IProps) => {
  const [form] = Form.useForm()

  const handleUpsert = async ({ action, recordId, filledStatus }: { action: string; recordId: string; filledStatus: string }) => {
    try {
      const response = await Http({
        url: `/lead/${recordId}`,
        method: HTTP_METHOD.PUT,
        data: {
          filledStatus,
          action,
        },
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        form.resetFields()
        props.setVisible()
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleCancel = () => {
    props.setVisible()
    form.resetFields()
  }

  return (
    <Modal
      title={'Add Entry'}
      open={props.visible}
      onCancel={handleCancel}
      footer={[
        <Space key={'action'}>
          <Button type="primary" onClick={form.submit} className="btn-padding">
            Save
          </Button>
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>
        </Space>,
      ]}
      width={600}
    >
      <Form
        form={form}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 14 }}
        autoComplete="off"
        onFinish={(value: IAddLead) => {
          const obj = {
            filledStatus: value.filledStatus,
            action: props.action,
            recordId: props.recordId,
          }
          void handleUpsert(obj)
        }}
        requiredMark="optional"
      >
        <Form.Item
          label="Status"
          name="filledStatus"
          rules={[
            {
              required: true,
              message: 'Select Status!',
            },
          ]}
        >
          <Select placeholder="Select Status" className="custom-select" style={{ width: '100%' }}>
            {OPTION_LIST.map((item) => (
              <Select.Option key={item.key}>{item.value}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CommonModal
