import React, { useCallback, useEffect, useState } from 'react'
import { Card, Space, Button, Tag, Form, Input, Modal, Select, InputNumber } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { PlusOutlined } from '@ant-design/icons'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { HTTP_METHOD, type ICustomer, type IAddCustomer } from 'Util/Interface'
import { toast } from 'sonner'

const Customer: React.FC = () => {
  const [tableData, setTableData] = useState<ICustomer[]>([])
  const [visible, setVisble] = useState(false)
  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [updateId, setUpdateId] = useState<string>('')

  const [form] = Form.useForm()

  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/customer`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
  }, [fetch])

  const handleDelete = async (params: ICustomer) => {
    try {
      const response = await Http(`/customer/${String(params.id)}`, {
        method: 'DELETE',
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        void fetch()
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpsert = async (param: IAddCustomer) => {
    try {
      const response = await Http({
        url: !isUpdated ? '/customer' : `/customer/${updateId}`,
        method: !isUpdated ? HTTP_METHOD.POST : HTTP_METHOD.PUT,
        data: param,
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        form.resetFields()
        setVisble(false)
        setIsUpdated(false)
        setUpdateId('')
        void fetch()
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleUpdate = (param: ICustomer) => {
    setIsUpdated(true)
    setUpdateId(param.id)
    form.setFieldsValue({
      fullName: param.fullName,
      companyName: param.companyName,
      email: param.email,
      mobile: param.mobile,
      address: param.address,
      returnType: param.returnType,
      regNo: param.regNo,
      status: param.status,
    })
    setVisble(true)
  }

  const handleCancel = () => {
    setVisble(false)
    setIsUpdated(false)
    setUpdateId('')
    form.resetFields()
  }

  const columns: ColumnsType<ICustomer> = [
    {
      title: 'Sr. No.',
      key: 'serial',
      dataIndex: 'serial',
      render: (_value, _record, index: number) => index + 1,
    },
    {
      title: 'Full Name',
      key: 'fullName',
      dataIndex: 'fullName',
      render: (text: string) => {
        return <span>{text}</span>
      },
    },
    {
      title: 'Registered No',
      key: 'regNo',
      dataIndex: 'regNo',
    },
    {
      title: 'Company Name',
      key: 'companyName',
      dataIndex: 'companyName',
      render: (text: string) => {
        return <span>{text}</span>
      },
    },
    {
      title: 'Email Address',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Mobile No.',
      key: 'mobile',
      dataIndex: 'mobile',
    },
    {
      title: 'Address',
      key: 'address',
      dataIndex: 'address',
    },
    {
      title: 'Return Type',
      key: 'returnType',
      dataIndex: 'returnType',
    },
    {
      title: 'Active',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <Tag color={value === 'active' ? 'green' : 'red'}>{value}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'actions',

      width: '15%',
      render: (_text, record: ICustomer) => {
        return (
          <Space>
            <Button
              onClick={() => {
                handleUpdate(record)
              }}
            >
              Update
            </Button>
            <Button
              danger
              onClick={() => {
                void handleDelete(record)
              }}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Customer List</h3>
              </div>
              <div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setVisble(true)
                  }}
                >
                  Add Customer
                </Button>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: ICustomer) => dataIndex?.id} scroll={{ x: 800 }} bordered size="middle" />
        </Card>
      </div>
      <Modal
        title={!isUpdated ? 'Add Customer' : 'Update Customer'}
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Space key={'action'}>
            <Button type="primary" onClick={form.submit} className="btn-padding">
              Save
            </Button>
            <Button danger onClick={handleCancel}>
              Cancel
            </Button>
          </Space>,
        ]}
        width={600}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          onFinish={(value: IAddCustomer) => {
            void handleUpsert(value)
          }}
          requiredMark="optional"
        >
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[
              {
                required: true,
                message: 'Please Enter Full Name!',
                type: 'string',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Enter Full Name" />
          </Form.Item>

          <Form.Item
            label="Reg No"
            name="regNo"
            rules={[
              {
                required: true,
                message: 'Please Enter Reg No!',
                type: 'string',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Enter Reg No" />
          </Form.Item>

          <Form.Item label="Company Name" name="companyName">
            <Input className="custom-input" placeholder="Enter Company Name" />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please Enter Email!',
                type: 'email',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Enter Email" />
          </Form.Item>

          <Form.Item
            label="Mobile"
            name="mobile"
            rules={[
              {
                required: true,
                message: 'Please Enter Mobile!',
              },
            ]}
          >
            <InputNumber className="custom-input-number" maxLength={10} placeholder="Enter Mobile" />
          </Form.Item>

          <Form.Item
            label="Address"
            name="address"
            rules={[
              {
                required: true,
                message: 'Please Enter Address!',
                type: 'string',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Enter Address" />
          </Form.Item>

          <Form.Item
            label="Return Type"
            name="returnType"
            rules={[
              {
                required: true,
                message: 'Please Enter Return Type!',
                type: 'string',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Enter Return Type" />
          </Form.Item>

          <Form.Item
            name="status"
            label="Status"
            rules={[
              {
                required: true,
                message: 'Select Status!',
              },
            ]}
          >
            <Select
              placeholder="Select Status"
              className="custom-select"
              style={{ width: '100%' }}
              options={[
                { value: 'active', label: 'Active' },
                { value: 'in_active', label: 'InActive' },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default Customer
