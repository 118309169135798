export const BASE_URL = 'https://crmapi.tallytdls.in/api/v1'

// export const BASE_URL = 'http://127.0.0.1:8000/api/v1'

export const BREAKPOINTS = {
  xs: 360,
  sm: 568,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1920,
} as const

const getMedia = <T extends number>(breakpoint: T): `(min-width: ${T}px)` => `(min-width: ${breakpoint}px)`

export const media = {
  xs: getMedia(BREAKPOINTS.xs),
  sm: getMedia(BREAKPOINTS.sm),
  md: getMedia(BREAKPOINTS.md),
  lg: getMedia(BREAKPOINTS.lg),
  xl: getMedia(BREAKPOINTS.xl),
  xxl: getMedia(BREAKPOINTS.xxl),
}

export const arrayFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

export const MONTH_LIST = [
  {
    key: 'JAN',
    value: 'JANUARY',
  },
  {
    key: 'FEB',
    value: 'FEBUARY',
  },
  {
    key: 'MAR',
    value: 'MARCH',
  },
  {
    key: 'APR',
    value: 'APRIL',
  },
  {
    key: 'MAY',
    value: 'MAY',
  },
  {
    key: 'JUNE',
    value: 'JUNE',
  },
  {
    key: 'JULY',
    value: 'JULY',
  },
  {
    key: 'AUG',
    value: 'AUGUST',
  },
  {
    key: 'SEP',
    value: 'SEPTEMBER',
  },
  {
    key: 'OCT',
    value: 'OCTOBER',
  },
  {
    key: 'NOV',
    value: 'NOVEMBER',
  },
  {
    key: 'DEC',
    value: 'DECEMBER',
  },
]

export const ACTION_LIST = [
  {
    key: 'sales',
    value: 'Sales',
  },
  {
    key: 'gstr1',
    value: 'GSTR-1',
  },
  {
    key: 'purchase',
    value: 'Purchase',
  },
  {
    key: 'gstr2',
    value: 'GSTR-2B',
  },
  {
    key: 'challan',
    value: 'Challan',
  },
  {
    key: 'gstjv',
    value: 'GSTJV',
  },
  {
    key: 'gstr3',
    value: 'GSTR-3B',
  },
  {
    key: 'bank',
    value: 'Bank',
  },
  {
    key: 'sbbank',
    value: 'SB Bank',
  },
  {
    key: 'sus',
    value: 'SUS',
  },
  {
    key: 'loanstmt',
    value: 'Loan Statment',
  },
  {
    key: 'credit',
    value: 'Credit',
  },
  {
    key: 'debit',
    value: 'Debit',
  },
]

export const OPTION_LIST = [
  {
    key: 'done',
    value: 'Done',
  },
  {
    key: 'pending',
    value: 'Pending',
  },
  {
    key: 'process',
    value: 'Process',
  },
  {
    key: 'query',
    value: 'Query',
  },
]
