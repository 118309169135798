export interface ILoginUser {
  email: string
  password: string
}

export interface IPagination {
  limit?: number
  skip?: number
  total?: number
}

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface ICategory {
  id: string
  title: string
  status: string
  created_at: string
  updated_at: string
}

export interface IAddCategory {
  id: string
  title: string
  status: string
}

export interface IStatus {
  id: string
  title: string
  status: string
  created_at: string
  updated_at: string
}

export interface IAddStatus {
  id: string
  title: string
  status: string
}

export interface IItemMaster {
  id: string
  title: string
  userRate: string
  partnerRate: string
  status: string
  created_at: string
  updated_at: string
}

export interface IAddItemMaster {
  id: string
  title: string
  userRate: string
  partnerRate: string
  status: string
}

export interface IUser {
  id: string
  fullName: string
  mobile: string
  email: string
  profileImage: string
  password: string
  status: string
  created_at: string
  updated_at: string
}

export interface IAddUser {
  id: string
  fullName: string
  mobile: string
  email: string
  profileImage: string
  password: string
  status: string
}

export interface IDeveloper {
  id: string
  fullName: string
  countryCode: string
  mobile: string
  email: string
  address: string
  status: string
  created_at: string
  updated_at: string
}

export interface IAddDeveloper {
  id: string
  fullName: string
  email: string
  mobile: string
  address: string
  status: string
}

export interface ICustomer {
  id: string
  fullName: string
  companyName: string
  regNo: string
  countryCode: string
  mobile: string
  email: string
  address: string
  returnType: string
  status: string
  created_at: string
  updated_at: string
}

export interface IAddCustomer {
  id: string
  fullName: string
  companyName: string
  regNo: string
  email: string
  mobile: string
  address: string
  returnType: string
  status: string
}

export interface IContact {
  id: string
  fullName: string
  email: string
  countryCode: string
  phone: string
  subject: string
  message: string
  status: string
  created_at: string
  updated_at: string
}

export interface IDashboard {
  completedLead: number
  salesLead: number
  newLead: number
  customer: number
  user: number
  allLead: number
}

// export interface ILead {
//   id: string
//   month: string
//   customerId: string
//   groupId: string
//   returnType: string
//   userId: string
//   sales: string
//   gstr1: string
//   purchase: string
//   gstr2B: string
//   gstr3B: string
//   challan: string
//   gstJV: string
//   bank: string
//   sbBank: string
//   sus: string
//   laonStmt: string
//   debit: string
//   credit: string
//   step: number
//   created_at: string
//   updated_at: string
//   customer_data: ICustomer
//   group_data: GroupData
//   user_data: UserData
// }

export interface ILeadLog {
  id: string
  month: string
  customerId: string
  action: string
  userId: string
  filledStatus: string
  filledDate: string
  status: string
  created_at: string
  updated_at: string
  customer_data: CustomerData
  user_data: UserData
}

export interface ILead {
  id: string
  month: string
  customerId: string
  salesUserId: string
  salesStatus: string
  salesDate: string
  gstr1UserId: string
  gstr1Status: string
  gstr1Date: string
  purchaseUserId: string
  purchaseStatus: string
  purchaseDate: string
  gstr2UserId: string
  gstr2Status: string
  gstr2Date: string
  challanUserId: string
  challanStatus: string
  challanDate: string
  gstjvUserId: string
  gstjvStatus: string
  gstjvDate: string
  gstr3UserId: string
  gstr3Status: string
  gstr3Date: string
  bankUserId: string
  bankStatus: string
  bankDate: string
  sbBankUserId: string
  sbBankStatus: string
  sbBankDate: string
  susUserId: string
  susStatus: string
  susDate: string
  loanStmtUserId: string
  loanStmtStatus: string
  loanStmtDate: string
  debitUserId: string
  debitStatus: string
  debitDate: string
  creditUserId: string
  creditStatus: string
  creditDate: string
  status: string
  created_at: string
  updated_at: string
  customer_data: CustomerData
  sales_user: UserData
  gstr1_user: UserData
  purchase_user: UserData
  gstr2_user: UserData
  challan_user: UserData
  gstjv_user: UserData
  gstr3_user: UserData
  bank_user: UserData
  sbbank_user: UserData
  sususer_user: UserData
  loan_user: UserData
  debit_user: UserData
  credit_user: UserData
}

export interface CustomerData {
  id: string
  fullName: string
  companyName: string
}

export interface GroupData {
  id: string
  fullName: string
  companyName: string
}

export interface UserData {
  id: string
  fullName: string
}

export interface IAddLead {
  id: string
  month: string
  customerId: string
  groupId: string
  returnType: string
  userId: string
  status: string
  filledStatus: string
}
