import axios, { type AxiosError } from 'axios'
import { BASE_URL } from './Constant'
import { toast } from 'sonner'

export interface ApiErrorData {
  message: string
}

// Create Instance
const Http = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

// Create Request Interceptor
Http.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('access-token')
    config.timeout = 4000
    config.headers.Authorization = `Bearer ${String(token)}`
    return config
  },
  async (error) => {
    return await Promise.reject(error)
  }
)

// Create Response Interceptor
Http.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error: AxiosError<ApiErrorData>) {
    if (axios.isAxiosError(error)) {
      if (!error?.response) {
        toast.error('Something Went Wrong')
        // localStorage.clear()
        // window.location.href = '/login'
      } else if (error.response?.status === 401) {
        toast.error('Unauthorized Access')
        localStorage.clear()
        window.location.href = '/login'
      }
    }
    return await Promise.reject(error || 'Something Went Wrong')
  }
)

export default Http
