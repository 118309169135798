import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Form, Modal, Select, Space } from 'antd'
import Table, { type ColumnsType } from 'antd/es/table'
import { type AxiosError } from 'axios'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { type ICustomer, type IAddLead, type ILeadLog, HTTP_METHOD } from 'Util/Interface'
import { toast } from 'sonner'
import dayjs from 'dayjs'
import { ACTION_LIST, MONTH_LIST, OPTION_LIST } from 'Util/Constant'
import { PlusOutlined } from '@ant-design/icons'

const AllLead: React.FC = () => {
  const [tableData, setTableData] = useState<ILeadLog[]>([])
  const [customerData, setCustomerData] = useState<ICustomer[]>([])
  const [visible, setVisble] = useState(false)
  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [updateId, setUpdateId] = useState<string>('')

  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/lead-log`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setTableData(response.data?.data)
      } else {
        setTableData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setTableData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  const masterData = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http(`/master`)
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        setCustomerData(response.data?.data?.customer)
      } else {
        setCustomerData([])
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      setCustomerData([])
      dispatch(setLoader(false))
    }
  }, [dispatch])

  useEffect(() => {
    void fetch()
    void masterData()
  }, [fetch, masterData])

  const handleUpsert = async (param: IAddLead) => {
    try {
      const response = await Http({
        url: !isUpdated ? '/lead' : `/lead/${updateId}`,
        method: !isUpdated ? HTTP_METHOD.POST : HTTP_METHOD.PUT,
        data: param,
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        form.resetFields()
        setVisble(false)
        setIsUpdated(false)
        setUpdateId('')
        void fetch()
      } else {
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
    } catch (error) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const handleCancel = () => {
    setVisble(false)
    setIsUpdated(false)
    setUpdateId('')
    form.resetFields()
  }

  const columns: ColumnsType<ILeadLog> = [
    {
      title: 'Sr. No.',
      key: 'serial',
      dataIndex: 'serial',
      render: (_value, _record, index: number) => index + 1,
    },
    {
      title: 'Month',
      key: 'month',
      dataIndex: 'month',
    },
    {
      title: 'Customer',
      key: 'cust',
      dataIndex: 'cust',
      render: (_value, record: ILeadLog) => <span>{record?.customer_data?.fullName}</span>,
    },
    {
      title: 'Entry Type',
      key: 'action',
      dataIndex: 'action',
      render: (value: string) => <span className="text-uppercase">{value}</span>,
    },
    {
      title: 'Entry Status',
      key: 'filledStatus',
      dataIndex: 'filledStatus',
      render: (value: string) => <span className="text-uppercase">{value}</span>,
    },
    {
      title: 'Added By',
      key: 'usr',
      dataIndex: 'usr',
      render: (_value, record: ILeadLog) => <span>{record?.user_data?.fullName}</span>,
    },
    {
      title: 'Created Date',
      key: 'date',
      dataIndex: 'date',
      render: (value) => <span>{dayjs(value).format('DD-MM-YYYY')}</span>,
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'actions',
    //   width: '15%',
    //   render: (_text, record: ILeadLog) => {
    //     return (
    //       <Space>
    //         <Button
    //           danger
    //           onClick={() => {
    //             setIsUpdated(true)
    //             setUpdateId(record.id)
    //             setVisble(true)
    //           }}
    //         >
    //           Add GSTR-2B
    //         </Button>
    //       </Space>
    //     )
    //   },
    // },
  ]

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Today Entry List</h3>
              </div>
              {/* <div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setVisble(true)
                  }}
                >
                  Add Entry
                </Button>
              </div> */}
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Table columns={columns} dataSource={tableData} rowKey={(dataIndex: ILeadLog) => dataIndex?.id} scroll={{ x: 800 }} bordered size="middle" />
        </Card>
      </div>
      {/* <Modal
        title={!isUpdated ? 'Add Sales' : 'Update Sales'}
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Space key={'action'}>
            <Button type="primary" onClick={form.submit} className="btn-padding">
              Save
            </Button>
            <Button danger onClick={handleCancel}>
              Cancel
            </Button>
          </Space>,
        ]}
        width={600}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          onFinish={(value: IAddLead) => {
            void handleUpsert(value)
          }}
          requiredMark="optional"
        >
          <Form.Item
            label="Month"
            name="month"
            rules={[
              {
                required: true,
                message: 'Select Month!',
              },
            ]}
          >
            <Select placeholder="Select Month" className="custom-select" style={{ width: '100%' }}>
              {MONTH_LIST.map((item) => (
                <Select.Option key={item.key}>{item.value}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Master"
            name="customerId"
            rules={[
              {
                required: true,
                message: 'Select Master!',
              },
            ]}
          >
            <Select placeholder="Select Master" className="custom-select" style={{ width: '100%' }}>
              {customerData.map((item) => (
                <Select.Option key={item.id}>{item.fullName}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Action"
            name="action"
            rules={[
              {
                required: true,
                message: 'Select Action!',
              },
            ]}
          >
            <Select placeholder="Select Action" className="custom-select" style={{ width: '100%' }}>
              {ACTION_LIST.map((item) => (
                <Select.Option key={item.key}>{item.value}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Status"
            name="filledStatus"
            rules={[
              {
                required: true,
                message: 'Select Status!',
              },
            ]}
          >
            <Select placeholder="Select Status" className="custom-select" style={{ width: '100%' }}>
              {OPTION_LIST.map((item) => (
                <Select.Option key={item.key}>{item.value}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal> */}
    </>
  )
}
export default AllLead
